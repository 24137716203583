import axios from '../utils/axiosInterceptors';

const baseURL = process.env.REACT_APP_LEXVID_API;

const getBundleContent = (bundle, stateURL, packageHours, campaign, couponCode, referredBy, adminId) => {
  let URL = `${baseURL}/api/Content/${bundle}/${stateURL}?v=2`;

  if (packageHours && campaign) {
    URL += `&campaign=${campaign}&package-hours=${packageHours}`;
  } else if (packageHours || campaign) {
    URL += `&`;
    if (campaign) URL += `campaign=${campaign}`;
    if (packageHours) URL += `package-hours=${packageHours}`;
  }

  if (adminId) {
    URL += `&adminId=${adminId}`;
  }


  if (referredBy) {
    URL += `&referredBy=${referredBy}`;
  }


  const bundleContent = axios({ method: 'GET', url: URL }).then((response) => {
    if (response.status === 200)
      return response.data;
  }
  ).catch((error) => {
    console.log(error);
  });

  return bundleContent;
}

const getBundles = (stateID) => {
  let URL = `${baseURL}/api/Bundle/ByState/${stateID}`;

  const bundles = axios({ method: 'GET', url: URL }).then((response) => {
    if (response.status === 200)
      return response.data;
  }
  ).catch((error) => {
    console.log(error);
  });

  return bundles;
}

const getPackagesContent = (stateURL, campaign, referredBy, adminId) => {
  if (!stateURL) stateURL = 'california-mcle';

  let URL = `${baseURL}/api/Content/packages/${stateURL}?v=2`;

  if (campaign) URL += `&campaign=${campaign}`;

  if (referredBy) {
    URL += `&referredBy=${referredBy}`;
  }

  if (adminId) {
    URL += `&adminId=${adminId}`;
  }


  const packagesContent = axios({ method: 'GET', url: URL }).then((response) => {
    if (response.status === 200)
      return response.data;
  }
  ).catch((error) => {
    console.log(error);
  });

  return packagesContent;
}

const getPackagesPricing = (stateID, campaignID, referredBy, adminId) => {
  let URL = `${baseURL}/api/product`;

  if (stateID)
    URL += `/${stateID}`;

  URL += "?v=2.9"

  if (referredBy)
    URL += `&referredBy=${referredBy}`;


  if (campaignID)
    URL += `&CampaignID=${campaignID}`;

  if (adminId) {
    URL += `&adminId=${adminId}`;
  }

  const packagesPricing =
    axios({ method: 'GET', url: URL })
      .then((response) => {
        if (response.status === 200)
          return response.data;
      });

  return packagesPricing;
}

const addLiveCourseFromCredits = (liveEvents) => {
  let URL = `${baseURL}/api/course/LiveAdd/AddLiveCourseFromCredits/${liveEvents}`;

  const addLiveCourseFromCredits =
    axios({ method: 'POST', url: URL })
      .then((response) => {
        if (response.status === 200)
          return response.data;
      });

  return addLiveCourseFromCredits;
}

export default {
  getBundleContent,
  getBundles,
  getPackagesContent,
  getPackagesPricing,
  addLiveCourseFromCredits
}