import React from "react";
import './DeleteIcon.scss';
import { useSelector } from "react-redux";

export default function DeleteIcon(props) {

	const { onClick, newType = false } = props;

	const imageMetaData = useSelector(state => state.content.imageMetaData);
	const rackspaceURL = process.env.REACT_APP_RACKSPACE_URL;
	const deleteIcon = 'images/delete-icon.svg';
	const deleteIconSvg = 'images/delete-icon.svg';
	
	return (newType ? 
		<img onClick={onClick} className='delete-icon-img'
			alt={imageMetaData && imageMetaData[deleteIcon]?.AltTag}
			src={`${rackspaceURL}/${deleteIconSvg}`} /> : 
		<img onClick={onClick} className='delete-icon-img'
			alt={imageMetaData && imageMetaData[deleteIcon]?.AltTag}
			src={`${rackspaceURL}/${deleteIcon}`} />)
}