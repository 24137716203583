import {
	GET_PAGE_CONTENT,
	GET_MULTISTATE_CONTENT_SUCCESS,
	GET_MULTISTATE_PACKAGES_CONTENT_SUCCESS,
	GET_DIDYOUKNOW_CONTENT_SUCCESS,
	RESET_CONTENT,
	GET_STATE_CONTENT,
	GET_STATE_CONTENT_SUCCESS,
	GET_STATE_CONTENT_ERROR,
	GET_IMAGE_META_DATA_SUCCESS,
	GET_PAGE_CONTENT_SECONDARY,
	GET_SIGNUP_CONTENT,
	GET_SIGNUP_SUCCESS_CONTENT_SUCCESS,
	GET_PAYMENT_CONTENT,
	GET_SIGNUP_SUCCESS_CONTENT
} from '../actions/types';

const initialState = {
	content: {
		ContentBlock: {
			'Complete CLE Hours': {
				Content: '',
				Header: '',
				SubHeader: ''
			},
			'Curated Online Subtitle': {
				Content: ''
			},
			'CLE Requirements': {
				Content: ''
			},
			'Curated Online Content': {
				Content: '',
				Header: ''
			}
		},
		MetaTag: {
			Description: '',
			Title: ''
		}
	},
	multiStateContent: {},
	multiStatePackagesContent: {},
	secondaryContent: {},
	didYouKnowContent: [],
	imageMetaData: [],
	carousel: [],
	paymentBenefits: null,
	signupSuccess: null,
	signupSuccessContentLoading: true
}

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_PAGE_CONTENT:
			{
				return {
					...state,
					content: action.payload
				}
			}
		case GET_SIGNUP_CONTENT:
			{
				return {
					...state,
					content: {
						...state.content,
						ContentBlock: {},
						MetaTag: {
							...state.content?.MetaTag,
							Description: action.payload?.Description,
							Title: action.payload?.Title
						}
					},
					carousel: action.payload.Carousel
				}
			}
		case GET_PAYMENT_CONTENT:
			{
				return {
					...state,
					content: {
						...state.content,
						ContentBlock: {},
						MetaTag: {
							...state.content?.MetaTag,
							Description: action.payload.Description,
							Title: action.payload.Title
						}
					},
					paymentBenefits: action.payload.Benefits
				}
			}
		case GET_SIGNUP_SUCCESS_CONTENT:
			{
				return {
					...state,
					signupSuccessContentLoading: true
				}
			}
		case GET_SIGNUP_SUCCESS_CONTENT_SUCCESS:
			{
				return {
					...state,
					content: {
						...state.content,
						ContentBlock: {},
						MetaTag: {
							...state.content?.MetaTag,
							Description: action.payload.Description,
							Title: action.payload.Title
						}
					},
					signupSuccess: action.payload.SignUpPurchaseNotification,
					signupSuccessContentLoading: false
				}
			}
		case GET_PAGE_CONTENT_SECONDARY:
			{
				return {
					...state,
					secondaryContent: action.payload
				}
			}
		case GET_MULTISTATE_CONTENT_SUCCESS:
			{
				return {
					...state,
					multiStateContent: action.payload,
					content: action.payload
				}
			}
		case GET_MULTISTATE_PACKAGES_CONTENT_SUCCESS:
			{
				return {
					...state,
					multiStatePackagesContent: action.payload,
					multiStateContent: { StateContentBlock: action.payload.StateContentBlock },
					content: action.payload
				}
			}
		case GET_DIDYOUKNOW_CONTENT_SUCCESS:
			{
				return {
					...state,
					didYouKnowContent: action.payload
				}
			}
		case RESET_CONTENT:
			{
				return {
					...state,
					content: null
				}
			}
		case GET_STATE_CONTENT:
			{
				return {
					...state
				}
			}
		case GET_STATE_CONTENT_SUCCESS:
			{
				return {
					...state,
					content: action.payload
				}
			}
		case GET_STATE_CONTENT_ERROR:
			{
				return {
					...state,
					error: action.payload
				}
			}
		case GET_IMAGE_META_DATA_SUCCESS:
			{
				return {
					...state,
					imageMetaData: action.payload
				}
			}
		default:
			return state;
	}
}