import {
	GET_BUNDLE_CONTENT,
	GET_BUNDLES,
	GET_PACKAGES_CONTENT,
	GET_PACKAGES_PRICING,
	UPDATE_SELECTED_BUNDLE, 
	GET_PAGE_CONTENT, 
	UPDATE_PURCHASED_BUNDLE, 
	UPDATE_SELECTED_LIVE_EVENTS,
	SET_INCLUDE_SELECTED_LIVE_EVENTS,
	UPDATE_SELECTED_BUNDLE_2,
	SET_SELECTED_FREE_LIVE_EVENT_IDS,
	SET_ONLY_FREE_LIVE_EVENTS
} from './types';
import ProductBundlesAPI from '../api/ProductBundlesAPI';
import { isEmpty } from 'lodash';
import Cookies from 'js-cookie';
import { resetPurchaseProduct, resetPurchaseProducts, updateOfflineCart } from "./accountActions";

export const getBundleContent = (bundle, stateURL, packageHours) => dispatch => {
	const campaignCookie = Cookies.get('campaign')
	const referredBy = Cookies.get('ReferredBy')
	const adminId = Cookies.get('admin-id')

	ProductBundlesAPI
		.getBundleContent(bundle, stateURL, packageHours, campaignCookie, null, referredBy, adminId)
		.then((bundleContent) => {
			dispatch({ type: GET_BUNDLE_CONTENT, payload: bundleContent });
			dispatch({ type: GET_PAGE_CONTENT, payload: bundleContent });
		})
		.catch((error) => {
			console.log(error);
		})
}

export const getBundles = (stateID) => dispatch => {
	if (!isEmpty(stateID)) return;
	ProductBundlesAPI
		.getBundles(stateID)
		.then((bundles) => {
			dispatch({ type: GET_BUNDLES, payload: bundles });
		})
		.catch((error) => {
			console.log(error);
		})
}

export const getPackagesContent = (stateURL) => dispatch => {

	const campaignCookie = Cookies.get('campaign')
	const referredBy = Cookies.get('ReferredBy')
	const adminId = Cookies.get('admin-id')
	
	ProductBundlesAPI
		.getPackagesContent(stateURL, campaignCookie, referredBy, adminId)
		.then((packagesContent) => {
			dispatch({ type: GET_PACKAGES_CONTENT, payload: packagesContent });
			dispatch({ type: GET_PAGE_CONTENT, payload: packagesContent });
		})
		.catch((error) => {
			console.log(error);
		})
}

export const getPackagesPricing = (stateIDs, campaignID, handleError) => dispatch => {
    const referrerCookie = Cookies.get('ReferredBy');


	return new Promise((resolve, reject) => {
		if (stateIDs > 0 || stateIDs?.length > 1) {
			ProductBundlesAPI
				.getPackagesPricing(stateIDs, campaignID, referrerCookie)
				.then((packagesPricing) => {
					dispatch({ type: GET_PACKAGES_PRICING, payload: packagesPricing });
					resolve(packagesPricing);
				})
				.catch((error) => {
					console.log(JSON.stringify(error));

					const errorToRethrow = { ...error, CustomMessage: `Unable to retrieve packages pricing for stateIDs: ${stateIDs}, campaignID: ${campaignID}` };

					console.log(errorToRethrow);
					// handleError(errorToRethrow);

					reject(error);
				})
		}
	});
}

export const clearSelectedBundles = () => dispatch => {
	dispatch({ type: UPDATE_SELECTED_BUNDLE, payload: null });
	dispatch({ type: UPDATE_SELECTED_BUNDLE_2, payload: null });
	dispatch(resetPurchaseProduct());
	dispatch(resetPurchaseProducts());
}

export const updateSelectedBundle = (selectedBundle) => dispatch => {
	dispatch({ type: UPDATE_SELECTED_BUNDLE, payload: selectedBundle });
}

export const updateSelectedBundlePromise = (selectedBundle) => dispatch => {
	return new Promise((resolve, reject) => {
		dispatch({ type: UPDATE_SELECTED_BUNDLE, payload: selectedBundle });
		resolve(selectedBundle);
	});
}

export const updateSelectedBundle2 = (selectedBundle) => dispatch => {
	dispatch({ type: UPDATE_SELECTED_BUNDLE_2, payload: selectedBundle });
}

export const updatePurchasedBundle = (purchasedBundle) => dispatch => {
	dispatch({ type: UPDATE_PURCHASED_BUNDLE, payload: purchasedBundle });
}

export const updateSelectedLiveEvents = (selectedLiveEvents) => dispatch => {
	dispatch({ type: UPDATE_SELECTED_LIVE_EVENTS, payload: selectedLiveEvents });
}

export const setIncludeSelectedLiveEvents = (doInclude) => dispatch => {
	return new Promise((resolve, reject) => {

		dispatch({ type: SET_INCLUDE_SELECTED_LIVE_EVENTS, payload: doInclude });

		if (doInclude) {
			dispatch(updateOfflineCart(null, null, false, null, true)).then(() => {
				resolve();
			});
		} else {
			resolve();
		}
	});
}

export const addLiveCourseFromCredits = (liveEvents) => dispatch => {

	return new Promise((resolve, reject) => {			
		ProductBundlesAPI
			.addLiveCourseFromCredits(liveEvents)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			})
		});
}

export const setRSVPForFreeLiveEvents = (selectedFreeEventIds, isOnlyFreeLiveEvents) => dispatch => {
	dispatch({ type: SET_SELECTED_FREE_LIVE_EVENT_IDS, payload: selectedFreeEventIds });
	dispatch({ type: SET_ONLY_FREE_LIVE_EVENTS, payload: isOnlyFreeLiveEvents });
}