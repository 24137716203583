import axios from '../utils/axiosInterceptors';

const baseURL = process.env.REACT_APP_LEXVID_API;

const getHomeContent = (stateURL) => {
  let URL = `${baseURL}/api/Content/Home`;

  const data = {
    ...(stateURL ? { stateURL: stateURL } : {})
  };

  const homeContent = axios({
    method: 'GET', url: URL, params: data
  })
    .then(response => {
      if (response.status === 200)
        return response.data;
    })
    .catch(error => {
      console.log(error);
    });

  return homeContent;
}

const getPrivacyPolicyContent = (isAdmin) => {
  let URL = `${baseURL}/api/Content?pageName=Privacy%20Policy`;


  if (isAdmin) URL += `?adminId=${isAdmin}`;

  const content = axios({ method: 'GET', url: URL })
    .then(response => {
      if (response.status === 200)
        return response.data;
    })
    .catch(error => {
      console.log(error);
    });

  return content;
}

const getSignupContent = (pageNumber, stateID) => {
  let URL = `${baseURL}/api/Content/SignUpCarousel/${pageNumber}`;

  if (stateID) URL += `/${stateID}`;

  const content = axios({ method: 'GET', url: URL })
    .then(response => {
      if (response.status === 200)
        return response.data;
    })
    .catch(error => {
      console.log(error);
    });

  return content;
}

const getPaymentContent = (productTypeId, stateAbbrev) => {
  let URL = `${baseURL}/api/Content/paymentBenefits/${productTypeId}/${stateAbbrev}`;

  const content = axios({ method: 'GET', url: URL })
    .then(response => {
      if (response.status === 200)
        return response.data;
    })
    .catch(error => {
      console.log(error);
    });

  return content;
}

const getSignupSuccessContent = (transactionID, purchaseMappingTypeID, memberID) => {
  let URL = `${baseURL}/api/content/SignUpPurchase/`;

  if (transactionID && purchaseMappingTypeID) URL += `${transactionID}/${purchaseMappingTypeID}`;
  else URL += `0/${memberID}`;

  const content = axios({ method: 'GET', url: URL })
    .then(response => {
      if (response.status === 200)
        return response.data;
    })
    .catch(error => {
      console.log(error);
    });

  return content;
}

const getPageContent = (pageName, stateURL, campaign, referredBy, isAdmin) => {
  let URL = `${baseURL}/api/Content/${encodeURIComponent(pageName)}`;


  if (stateURL && stateURL.length > 0) 
    URL += `/${encodeURIComponent(stateURL)}?v=1`
  else
    URL += `?v=1.1`


  if (referredBy) URL += `&referredBy=${referredBy}`;
  if (isAdmin) URL += `&adminId=${isAdmin}`;
  if (campaign) URL += `&campaign=${campaign}`;

  // const data = {
  //     ...(stateURL ? { stateURL: stateURL } : {})
  // };

  // , params: data
  const content = axios({
    method: 'GET', url: URL
  })
    .then(response => {
      if (response.status === 200)
        return response.data;
    })
    .catch(error => {
      console.log(error);
    });

  return content;
}

const getCourseReviewPageContent = (courseURL, isAdmin) => {
  let URL = `${baseURL}/api/Content?pageName=course-reviews&courseURL=${encodeURIComponent(courseURL)}`;
  if (isAdmin) URL += `?adminId=${isAdmin}`;

  const content = axios({
    method: 'GET', url: URL
  })
    .then(response => {
      if (response.status === 200)
        return response.data;
    })
    .catch(error => {
      console.log(error);
    });

  return content;
}

const getMultiStateContent = (stateIDs, referredBy) => {
  let URL = `${baseURL}/api/Content/multistate-state/${stateIDs}`;
  if (referredBy) URL += `?referredBy=${referredBy}`;

  const stateContent = axios({ method: 'GET', url: URL })
    .then(response => {
      if (response.status === 200)
        return response.data;
    })
    .catch(error => {
      console.log(error);
    });

  return stateContent;
}

const getMultiStatePackagesContent = (stateIDs, campaign, referredBy) => {
  let URL = `${baseURL}/api/Content/multistate-packages/${stateIDs}?v=1.3`;

  if (campaign) URL += `&campaign=${campaign}`;
  if (referredBy) URL += `&referredBy=${referredBy}`;
  const stateContent = axios({ method: 'GET', url: URL })
    .then(response => {
      if (response.status === 200)
        return response.data;
    })
    .catch(error => {
      console.log(error);
    });

  return stateContent;
}

const getDidYouKnowContent = () => {
  let URL = `${baseURL}/api/Content/DidYouKnow`;

  const didYouKnowContent = axios({
    method: 'GET',
    url: URL
  }).then(response => {
    if (response.status === 200)
      return response.data;
  }).catch(error => {
    console.log(error);
  });

  return didYouKnowContent;

}

const getStateContent = (stateURL, campaign, isAdmin) => {
  let URL = `${baseURL}/api/Content/State/${stateURL}?v=2.1`;

  if (campaign) URL += `&campaign=${campaign}`;
  if (isAdmin) URL += `&adminId=${isAdmin}`;

  const stateContent = axios({ method: 'GET', url: URL })
    .then(response => {
      if (response.status === 200)
        return response.data;
    }
    )
    .catch(error => {
      console.log(error);
    });

  return stateContent;
}


const getImageMetaData = () => {
  let URL = `${baseURL}/api/Content/ImageMetaData`;

  const response = axios({
    method: 'GET',
    url: URL
  }).then((response) => {
    if (response.status === 200)
      return response.data;
  }).catch((error) => {
    console.log(error);
  });

  return response;
}

const getLiveCourseContent = (stateId) => {
  // let URL = `${baseURL}/api/Content/LiveCourse/${stateId}`;
  let URL = `${baseURL}/api/Content/Live-Course-List/${stateId}`;

  const stateContent = axios({ method: 'GET', url: URL })
    .then(response => {
      if (response.status === 200)
        return response.data;
    }
    )
    .catch(error => {
      console.log(error);
    });

  return stateContent;
}

const getSpeakersContent = (searchSpecialty, currentPage, totalPages) => {
  let URL = `${baseURL}/api/content/speakers?v=1.1`;

	const speakersContent = axios({ 
		method: 'GET', 
		url: URL, 
		params: { searchSpecialty: searchSpecialty, currentPage: currentPage, totalPages: totalPages } 
	}).then(response => {
		if (response.status === 200)
			return response.data;
	}).catch(error => {
		console.log(error);
	});

  return speakersContent;
}


export default {
  getHomeContent,
  getPrivacyPolicyContent,
  getPageContent,
  getMultiStateContent,
  getMultiStatePackagesContent,
  getCourseReviewPageContent,
  getDidYouKnowContent,
  getStateContent,
  getImageMetaData,
  getLiveCourseContent,
  getSpeakersContent,
  getSignupContent,
  getSignupSuccessContent,
  getPaymentContent
}