import { isEmpty } from 'lodash';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from "react-redux";
import { addLiveCourseFromCredits } from "../actions/productBundlesActions";
import { SET_SELECTED_FREE_LIVE_EVENT_IDS } from "../actions/types";


const useCheckoutUtils = ({ selectedBundle, selectedBundle2, stateIDs }) => {

	const dispatch = useDispatch();

	const campaign = useSelector(state => state.miscellaneous.campaign);
	const selectedFreeEventIds = useSelector(state => state.productBundles.selectedFreeEventIds);

	const initialCoupon = function () {
		if (selectedBundle?.couponCode != null && selectedBundle?.couponCode > '')
			return selectedBundle?.couponCode

		const referrerCookie = Cookies.get('ReferredBy');
		if (referrerCookie === null || referrerCookie === "" || referrerCookie === undefined)
			return "";

		return "LexVid Referral Program"
	}

	const getProductItem = (selectedBundle) => {
		const productItem = {
			ProductType: selectedBundle?.bundle,
			PurchasedCredits: selectedBundle?.hours ? selectedBundle?.hours : 0			
		};

		if (selectedBundle?.courseID) productItem.CourseID = selectedBundle?.courseID;
		if (selectedBundle?.courseName) productItem.CourseName = selectedBundle?.courseName;

		const selectedBundleType = selectedBundle?.bundle?.toLowerCase();

		if (['all-inclusive'].includes(selectedBundleType)) {
			productItem.PurchasedCredits = selectedBundle?.onDemandHours;
			productItem.PurchasedLiveCredits = selectedBundle?.liveHours;
		} else if (['custom-live'].includes(selectedBundleType)) {
			productItem.PurchasedCredits = 0;
			productItem.PurchasedLiveCredits = selectedBundle?.hours;
		} else if (selectedBundleType === 'a-la-carte') {
			productItem.PurchasedCredits = selectedBundle?.hours;
			productItem.PurchasedLiveCredits = selectedBundle?.hoursLive;
			productItem.Type = selectedBundle?.type;
			productItem.productItems = selectedBundle?.productItems;
		} else if (selectedBundleType === 'bundle') {
			productItem.BundleID = selectedBundle?.bundleID ? selectedBundle?.bundleID : null;
		}

		// always need to include purchasedLiveCredits (even if 0) for the API to work
		if (!productItem.PurchasedLiveCredits) productItem.PurchasedLiveCredits = 0;

		return productItem;
	}

	const getProductsInfo = () => {
		const productItem1 = getProductItem(selectedBundle);
		const productItem2 = isEmpty(selectedBundle2) ? undefined : getProductItem(selectedBundle2);
		let productItems = [productItem1];
		if (productItem2) productItems.push(productItem2);

		const productsInfo = {
			ProductItems: productItems,
			StateIDs: stateIDs,
			CouponCode: initialCoupon(),
			CampaignID: campaign ? campaign : '',
			CreditCard: '',
			ExpirationYear: '',
			ExpirationMonth: '',
		}
		
		return productsInfo;
	}

	const rsvpForFreeEvents = () => {
		if (!isEmpty(selectedFreeEventIds)) {
			dispatch(addLiveCourseFromCredits(selectedFreeEventIds));

			dispatch({
				type: SET_SELECTED_FREE_LIVE_EVENT_IDS,
				payload: null
			});
		}
	}

  return { getProductsInfo, rsvpForFreeEvents }

};

export default useCheckoutUtils;