import React, { useState } from 'react';
import { Form, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import NavbarBrand from './NavbarBrand';
import useShowLiveEvents from "../../hooks/useShowLiveEvents";
import CartIcon from "../common/CartIcon/CartIcon";
import useNumberProductsInCart from "../../hooks/useNumberProductsInCart";
import { clearLiveCart, updateOfflineCart } from "../../actions/accountActions";
import { clearSelectedBundles } from "../../actions/productBundlesActions";
import { CLEAR_OFFLINE_CART } from "../../actions/types";


const NotAuthenticatedMenu = (props) => {

	const history = useHistory();
	const dispatch = useDispatch();

	const numberOfProducts = useNumberProductsInCart();
	const shoppingCartMobileMenuText = numberOfProducts > 0 ? `Shopping Cart (${numberOfProducts})` : 'Shopping Cart';

	const homeLink = '/';

	const stateURL = useSelector(state => state.states.stateURL);
	const multiStateArray = useSelector(state => state.states.multiStateArray);

	const showLiveEvents = useShowLiveEvents({ isMultiState: multiStateArray?.length > 1, includeLiveNotRequired: true });

	const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false);

	const collapseMobileMenu = () => setTimeout(() => { setMobileMenuExpanded(false) }, 150)

	const navigateToCourseListing = (event, type) => {
		event.preventDefault();
		event.stopPropagation();

		let newUrl = '';
		switch (type) {
			case "state":
				newUrl = `/${stateURL}/courses?pageNumber=1&sortBy=popular`;
				break;
			case "multi":
				newUrl = '/multi-state-cle/courses?pageNumber=1&sortBy=popular';
				break;
			default:
				newUrl = '/cle-courses?pageNumber=1&sortBy=popular';
				break;
		}

		history.push('/loading');
		setTimeout(() => {
			// &key=${Date.now()}
			history.replace(newUrl)
		}, 100);

		collapseMobileMenu();
	}

	const navigateToLiveCourses = (event, type) => {
		event.preventDefault();
		event.stopPropagation();

		let newUrl = '';
		switch (type) {
			case "state":
				newUrl = `/${stateURL}/packages/live`;
				break;
			case "multi":
				newUrl = `/${stateURL}/packages/live`;
				break;
			default:
				newUrl = `/${stateURL}/packages/live`;
				break;
		}

		history.push('/loading');
		setTimeout(() => {
			// &key=${Date.now()}
			history.replace(newUrl)
		}, 100);

		collapseMobileMenu();
	}


	const cartId = useSelector(state => state.productBundles.cartId);

	const getStartedForFreeClick = (event) => {
		event.preventDefault();
		event.stopPropagation();

		// Clear the cart
		dispatch(clearSelectedBundles());
		if(cartId) dispatch(clearLiveCart(cartId, false, false));
		
		dispatch(updateOfflineCart(null)).then(() => {
			dispatch({ type: CLEAR_OFFLINE_CART });
			history.push('/sign-up');
			collapseMobileMenu();
		});
	}


	return <Navbar expand='lg' variant='light' className='header-nav navbar-not-auth' onToggle={(expanded) => setMobileMenuExpanded(expanded)} expanded={mobileMenuExpanded} role="menu">

		<NavbarBrand homeLink={homeLink} />

		<a href='https://help.lexvid.com/' target='_blank' rel='noopener noreferrer' className='help-link-mobile header-item d-lg-none d-xl-none ml-auto mr-2' role="menuitem">Help</a>

		<Link onClick={getStartedForFreeClick} className='button button-blue button-signup d-lg-none d-xl-none mr-2' role="menuitem">
			Get Started for Free
		</Link>

		<Navbar.Toggle className='d-flex d-lg-none d-xl-none ml-1'>
			<span>MENU</span> {mobileMenuExpanded ? <i className="fas fa-caret-up fa-open-menu"></i> : <i className="fas fa-caret-down fa-open-menu"></i>}
		</Navbar.Toggle>

		<Navbar.Collapse className='responsive-navbar-nav'>
			<Nav className='mr-auto navbar menu-link-items' role="menu">
				<Link className='header-item d-none d-xl-block' to='/' onClick={collapseMobileMenu} role="menuitem">Home</Link>
				<Link className='header-item d-lg-none d-xl-none home-link' to={homeLink} role="menuitem">Home</Link>

				{multiStateArray?.length > 1 ?
					<>
						<FindCLECoursesLink 
							type={'multi'} 
							navigateToCourseListing={navigateToCourseListing} 
							navigateToLiveCourses={navigateToLiveCourses}
							showLiveEvents={showLiveEvents} />

						<PackagesAndPricingLink collapseMobileMenu={collapseMobileMenu} to={'/multi-state-cle/packages'} />
					</>
					:
					stateURL ?
						<>
							<FindCLECoursesLink 
								type={'state'}
								navigateToCourseListing={navigateToCourseListing}
								navigateToLiveCourses={navigateToLiveCourses}
								showLiveEvents={showLiveEvents} />

							<PackagesAndPricingLink collapseMobileMenu={collapseMobileMenu} to={`/${stateURL}/packages`} />
						</>
						:
						<>
							<FindCLECoursesLink
								type={''}
								navigateToCourseListing={navigateToCourseListing}
								navigateToLiveCourses={navigateToLiveCourses}								
								showLiveEvents={showLiveEvents} />

							<PackagesAndPricingLink collapseMobileMenu={collapseMobileMenu} to={`/cle-packages`} />
						</>
				}

				<Link className='header-item d-lg-none d-xl-none home-link' to='/cart' onClick={collapseMobileMenu} role="menuitem">{shoppingCartMobileMenuText}</Link>

				<div className="help-phone-wrap">
					<a className='header-item d-none d-lg-block' href='https://help.lexvid.com/' target='_blank' rel='noopener noreferrer' onClick={collapseMobileMenu} role="menuitem">Help</a>

					<a className="header-item phone-number" href="tel:1-877-327-1226" role="menuitem"><i className="fa fa-phone fa-rotate-90"></i> 1-877-327-1226</a>
				</div>

			</Nav>

			<CartIcon />

			<Form inline>
				<Link onClick={getStartedForFreeClick} role='button' className='button button-blue button-signup d-xs-block d-none d-lg-block'>
					Get Started for Free
				</Link>
				<Link to='/sign-in' role='button' className='button button-white button-signin' onClick={collapseMobileMenu}>
					Sign In
				</Link>
			</Form>
		</Navbar.Collapse>

	</Navbar>
}

const FindCLECoursesLink = (props) => {

	const [showFindCLECourses, setShowFindCLECourses] = useState(false);

	const { type, navigateToCourseListing, navigateToLiveCourses, showLiveEvents } = props;

	if (showLiveEvents) {
		return <>
			<NavDropdown
				title='Find CLE Courses'
				className='find-cle-courses d-none d-lg-block'
				onMouseEnter={() => setShowFindCLECourses(true)}
				onMouseLeave={() => setShowFindCLECourses(false)}
				onClick={(event) => navigateToCourseListing(event)}
				show={showFindCLECourses}
			>
				<NavDropdown.Item onClick={(event) => navigateToCourseListing(event, type)}>
					<div className='arrow-right'></div> On Demand Courses
				</NavDropdown.Item>
				<NavDropdown.Item onClick={(event) => navigateToLiveCourses(event, type)}>
					<div className='arrow-right'></div> Live Courses
				</NavDropdown.Item>
			</NavDropdown>
			<Link className='header-item d-lg-none d-xl-none' to={''} onClick={(event) => navigateToCourseListing(event, type)}>On Demand Courses</Link>
			<Link className='header-item d-lg-none d-xl-none' to={''} onClick={(event) => navigateToLiveCourses(event, type)}>Live Courses</Link>
		</>;
	} else {
		return <Link className='header-item' to={''} onClick={event => navigateToCourseListing(event, type)} role="menuitem">Find CLE Courses</Link>;
	}
}

const PackagesAndPricingLink = (props) => {

	const { collapseMobileMenu, to } = props;

	return <Link className='header-item' to={to} onClick={collapseMobileMenu} role="menuitem">Packages & Pricing</Link>;
}

export default NotAuthenticatedMenu;