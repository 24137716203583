import React from 'react';
import './FinishSettingUpAccount.scss';
import { useDispatch, useSelector } from 'react-redux';
import { SET_SHOW_FINISH_SETTING_UP_ACCOUNT } from '../../../actions/types';

const FinishSettingUpAccount = () => {

  const dispatch = useDispatch();

  const user = useSelector(state => state.auth.user);
  const userHasBar = user && user.Bars && user.Bars.length > 0;

  const showFinishSettingUpAccountRedux = useSelector(state => state.miscellaneous.showFinishSettingUpAccount);

  if (userHasBar && showFinishSettingUpAccountRedux) {
    dispatch({ type: SET_SHOW_FINISH_SETTING_UP_ACCOUNT, payload: false });
  }

  return (
    <div className="finish-setting-up-account-wrap">
      <div>
        <span className="fa-stack">
          <i className="fas fa-circle"></i>
          <i className="fas fa-exclamation fa-stack-1x fa-inverse"></i>
        </span>
        Please finish setting up your account before continuing
        <i className="fas fa-arrow-right"></i>
      </div>
    </div>
  );
};

export default FinishSettingUpAccount;