import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { getOnDemandProductText } from "../../../utils/paymentUtils";
import DeleteIcon from "../../common/DeleteIcon/DeleteIcon";
import useNumberProductsInCart from "../../../hooks/useNumberProductsInCart";
import useLiveEventsCheckoutData from "../../../hooks/useLiveEventsCheckoutData";
import { Button } from "react-bootstrap";
import Utils from "../../../utils";
import { getProductPrice2, removeProductFromLiveCart, updateOfflineCart } from "../../../actions/accountActions";
import { RESET_PURCHASE_PRODUCTS, UPDATE_SELECTED_BUNDLE, UPDATE_SELECTED_BUNDLE_2 } from "../../../actions/types";
import useCheckoutUtils from "../../../hooks/useCheckoutUtils";


const  CartItems = ({ allowDelete = false, deletePosition = 'right', showTotal = false, showButtons = false, handleEditCart, handleCheckout }) => {

  const dispatch = useDispatch();

  const deletePositionLeft = deletePosition === 'left';

  const numberOfProducts = useNumberProductsInCart();
  const { selectedLiveEvents, includeSelectedLiveEvents } = useLiveEventsCheckoutData(true);

  const user = useSelector(state => state.auth.user);
	const pageContent = useSelector(state => state.content.content);

  const selectedBundle = useSelector(state => state.productBundles.selectedBundle);
  const selectedBundleType = selectedBundle?.bundle;
  const selectedBundle2 = useSelector(state => state.productBundles.selectedBundle2);
  const selectedBundle2Type = selectedBundle2?.bundle;
	const purchaseProductObj = useSelector(state => state.account.purchaseProduct);
	const purchaseProductsObj = useSelector(state => state.account.purchaseProducts);
	const selectedBundleStateObj = user?.Bars?.find((bar) => bar?.State?.ID === selectedBundle?.selectedStateID)?.State;

	const cartId = useSelector(state => state.productBundles.cartId);

  const stateFromRedux = useSelector(state => state.states.stateObject);
  const multiStateArrayFromRedux = useSelector(state => state.states.multiStateArray);

  const multiStateArray = !isEmpty(user?.Bars) ? user?.Bars?.map(bar => bar?.State) :
    !isEmpty(multiStateArrayFromRedux) ? multiStateArrayFromRedux : [stateFromRedux];

  const isMultiState = multiStateArray?.length > 1;
	const stateObject = user?.Bars?.[0]?.State ?? stateFromRedux;

  const deleteOndemandClick = (productNumber) => {
    dispatch({ type: RESET_PURCHASE_PRODUCTS });
    dispatch({ type: UPDATE_SELECTED_BUNDLE_2, payload: null }); // since we have only one remaining ondemand product, we can set selectedBundle2 to null
    // let newProductsInfo = {};

    if (productNumber === 1) {

      let product = {};
      if (selectedBundle2 && selectedBundle2.bundle) {
        product = {
          ...selectedBundle2.bundle,
          ProductType: selectedBundle2.bundle.toLowerCase() === 'a-la-carte' ? 'AlaCarte' : selectedBundle2.bundle, 
          Price: selectedBundle2.salePrice > 0 ? selectedBundle2.salePrice : selectedBundle2.price,
          RetailPrice: selectedBundle2.price
        };
        dispatch(updateOfflineCart(null, cartId, false, product));
      } else {
        dispatch(updateOfflineCart(null, cartId, false, null));
      }

      dispatch({ type: UPDATE_SELECTED_BUNDLE, payload: selectedBundle2 });
      // newProductsInfo = { ...productsInfo, ProductItems: [productsInfo.ProductItems[1]] };

    } else if (productNumber === 2) {

      // newProductsInfo = { ...productsInfo, ProductItems: [productsInfo.ProductItems[0]] };
      
      dispatch(updateOfflineCart(null, cartId, false, null, true));			
    }
    
    // setProductsInfo(newProductsInfo);

    if (!cartId) return;
  }

  const deleteLiveClick = (eventId) => {
    dispatch(removeProductFromLiveCart(cartId, eventId)).then(data => {

    });
  }
  

  const getPrices = (purchaseProductsObj, selectedBundle, productNumber) => {
    const isProduct1 = !productNumber || productNumber === 1;

    let price = isProduct1 ? purchaseProductsObj?.ProductItems?.[0]?.Price : purchaseProductsObj?.ProductItems?.[1]?.Price;
    let retailPrice = isProduct1 ? purchaseProductsObj?.ProductItems?.[0]?.RetailPrice : purchaseProductsObj?.ProductItems?.[1]?.RetailPrice;

    if (!price) price = selectedBundle?.salePrice;
    if (!retailPrice) retailPrice = selectedBundle?.price;

    const isSale =  price > 0 && price < retailPrice;

    return { price, retailPrice, isSale };
  }

  const getOnDemandProductPriceText = (purchaseProductsObj, selectedBundle, productNumber) => {

    const { price, retailPrice, isSale } = getPrices(purchaseProductsObj, selectedBundle, productNumber);

    const priceText = isSale ?
        `<span class="price-strike">$${Utils.toCurrencyWith2FloatingPoint(retailPrice)}</span>
        <span class="price-sale">$${Utils.toCurrencyWith2FloatingPoint(price)}</span>` :
        `$${Utils.toCurrencyWith2FloatingPoint(price)}`;

    return priceText;
  }

	const onDemandProductText =
		getOnDemandProductText(true, selectedBundleType?.toLowerCase(), purchaseProductsObj, pageContent, multiStateArray,
			stateObject, selectedBundleStateObj, includeSelectedLiveEvents, selectedBundle, 1, isMultiState, purchaseProductObj);
  const onDemandProductText2 =
    getOnDemandProductText(true, selectedBundle2Type?.toLowerCase(), purchaseProductsObj, pageContent, multiStateArray,
      stateObject, selectedBundleStateObj, includeSelectedLiveEvents, selectedBundle2, 2, isMultiState);
  // Price text for onDemand products
  const onDemandProductPriceText = getOnDemandProductPriceText(purchaseProductsObj, selectedBundle, 1);
  const onDemandProductPriceText2 = getOnDemandProductPriceText(purchaseProductsObj, selectedBundle2, 2);


  // TODO: refactor and reuse with Payment component (useCheckoutData.js) AND Cart component
  const onDemandTotal = purchaseProductsObj?.ProductItems?.reduce((total, product) => {
		return total + ((product.ProductTypeID || product.ProductType) ? (product.Price ? Number(product.Price) : product.RetailPrice ? Number(product.RetailPrice) : 0) : 0);
	}, 0);

  const onDemandTotalFromSelectedBundle = 
    (selectedBundle?.salePrice ? Number(selectedBundle.salePrice) : (selectedBundle?.price ? Number(selectedBundle.price) : 0)) + 
    (selectedBundle2?.salePrice ? Number(selectedBundle2.salePrice) : (selectedBundle2?.price ? Number(selectedBundle2.price) : 0));
  
	const liveCoursesTotal = includeSelectedLiveEvents ? selectedLiveEvents?.reduce((total, liveEvent) => {
		return total + (liveEvent.DiscountedPrice ?? liveEvent.Price);
	}, 0) : 0;

  const totalPrice = (onDemandTotal ?? onDemandTotalFromSelectedBundle) + liveCoursesTotal;

  const calculateDiscount = (bundle, purchaseProductsObj, productNumber) => {
    const { price, retailPrice, isSale } = getPrices(purchaseProductsObj, bundle, productNumber);

    if (price && retailPrice && isSale) {
      return Number(retailPrice) - Number(price);
    }
    return 0;
  }

  const onDemandDiscount = calculateDiscount(selectedBundle, purchaseProductsObj, 1) + calculateDiscount(selectedBundle2, purchaseProductsObj, 2);
  
  let liveEventsDiscount = includeSelectedLiveEvents ? selectedLiveEvents?.reduce((total, liveEvent) => {
		return total + liveEvent.DiscountAmount;
	}, 0) : 0;

  const totalDiscount = onDemandDiscount + liveEventsDiscount;

  //
  const [priceFetched, setPriceFetched] = useState(false);
  const stateIDs = multiStateArray?.map(state => state.ID ) ?? [7];
	const { getProductsInfo } = useCheckoutUtils({ selectedBundle, selectedBundle2, stateIDs });
  let productsInfo = getProductsInfo();
  if (purchaseProductsObj?.CouponCode && purchaseProductsObj?.CouponCode !== "Limited Time Sale") productsInfo.CouponCode = purchaseProductsObj.CouponCode;  
  // console.log(productsInfo);
  
  useEffect(() => {
    if (priceFetched || isEmpty(productsInfo) || isEmpty(productsInfo?.ProductItems) || !productsInfo?.ProductItems[0]?.ProductType) return;

    dispatch(getProductPrice2(productsInfo)).then(data => {
      // console.log(data);
      setPriceFetched(true);
    });

  }, [dispatch, productsInfo, priceFetched]);


  return <>
    {/* First OD product */}
    {!isEmpty(selectedBundle) && !isEmpty(selectedBundleType) ?
      <div className='product-line'>
        {allowDelete && deletePositionLeft ? <DeleteIcon onClick={() => deleteOndemandClick(1)} newType={true} /> : null}
        <div className='product-text' dangerouslySetInnerHTML={{ __html: onDemandProductText }} />
        <div className='product-price' dangerouslySetInnerHTML={{ __html: onDemandProductPriceText }} />
        {allowDelete && !deletePositionLeft && numberOfProducts > 1 ? <DeleteIcon onClick={() => deleteOndemandClick(1)} newType={true} /> : null}
      </div> : null}

    {/* Second OD product */}
    {!isEmpty(selectedBundle2) ?
      <div className='product-line'>
        {allowDelete && deletePositionLeft ? <DeleteIcon onClick={() => deleteOndemandClick(2)} newType={true} /> : null}
        <div className='product-text' dangerouslySetInnerHTML={{ __html: onDemandProductText2 }} />
        <div className='product-price'>{onDemandProductPriceText2}</div>
        {allowDelete && !deletePositionLeft && numberOfProducts > 1 ? <DeleteIcon onClick={() => deleteOndemandClick(2)} newType={true} /> : null}
      </div> : null}

    {/* Live products */}
    {includeSelectedLiveEvents ?
      selectedLiveEvents.map((liveEvent, index) => {
        // const price = liveEvent.RetailPrice ?? liveEvent.Price;
        const isSale = liveEvent.Price < liveEvent.RetailPrice;
        const priceText = isSale ?
          `<span class="price-strike">$${Utils.toCurrencyWith2FloatingPoint(liveEvent.RetailPrice)}</span>
          <span class="price-sale">$${Utils.toCurrencyWith2FloatingPoint(liveEvent.Price)}</span>` :
          `$${Utils.toCurrencyWith2FloatingPoint(liveEvent.Price)}`;

        return <div className='product-line' key={index}>
          {allowDelete && deletePositionLeft ? <DeleteIcon onClick={() => deleteLiveClick(liveEvent.LiveEventsID)} newType={true} /> : null}
          <div className='product-text'>
            <b>{liveEvent.CourseLength}</b><br />
            {liveEvent.CourseTitleAndDate}
          </div>
          {/* <div className='product-price'>
            ${Number(price)?.toFixed(2)}
          </div> */}
          <div className='product-price' dangerouslySetInnerHTML={{ __html: priceText }} />
          {allowDelete && !deletePositionLeft && numberOfProducts > 1 ? <DeleteIcon onClick={() => deleteLiveClick(liveEvent.LiveEventsID)} newType={true} /> : null}
        </div>
      }) : null}

    {showTotal || showButtons ? <div className="total-and-buttons">
    
      {showTotal ? 
        <>
          {totalDiscount > 0 ? <div className="discount">Limited Time Sale: <span>{`-$${totalDiscount?.toFixed(2)}`}</span></div> : null}
          <div className="total">Total Amount: <span>{`$${totalPrice?.toFixed(2)}`}</span></div> 
        </>
      : null}

      {showButtons ? <div className="buttons">
        <Button variant="primary-blue" onClick={handleEditCart}>Edit Cart</Button>
        <Button onClick={handleCheckout}>Checkout Now</Button>
      </div> : null}
    
    </div> : null}

  </>
};

export default CartItems;