import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_SHOW_CART_OVERLAY } from "../../../actions/types";
import './CartOverlay.scss';
import Utils from '../../../utils';
import useNumberProductsInCart from "../../../hooks/useNumberProductsInCart";
import { Button } from "react-bootstrap";
import emptyCartImg from '../../../assets/_new/empty-cart.png';
import { Link, useHistory } from "react-router-dom";
import CartItems from "../../Cart/components/CartItems";
import useIsAuthenticated from "../../../hooks/useIsAuthenticated";

const CartOverlay = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  
  const isAuthenticated = useIsAuthenticated();
  const numberOfProducts = useNumberProductsInCart();

	const multiStateArray = useSelector(state => state.states.multiStateArray);
	const stateURL = useSelector(state => state.states.stateURL);

  // TODO: refactor
  const packagesUrl = isAuthenticated ? '/secure/cle-packages' : 
    multiStateArray?.length > 1 ? '/multi-state-cle/packages' : stateURL ? `/${stateURL}/packages` : '/cle-packages';

  const cartUrl = isAuthenticated ? '/secure/cart' : '/cart';

  const checkoutUrl = isAuthenticated ? '/secure/payment' : '/check-out';

  const hideOverlay = () => {
    dispatch({ type: SET_SHOW_CART_OVERLAY, payload: false });
  }

  const handleOnHide = () => {
    hideOverlay();
  }

  const handleViewPackagesClick = () => {
    hideOverlay();
  }

  const handleEditCart = () => {
    hideOverlay();
    history.push(cartUrl);
  }

  const handleCheckout = () => {
    hideOverlay();
    history.push(checkoutUrl);
  }

  return <div className="cart-overlay-backdrop">
    
    <div className="cart-overlay-wrap">
      
      <div className="cart-overlay-title">
        {numberOfProducts > 0 ? 
          <div>
            {`${numberOfProducts} ${Utils.pluralize('Item', numberOfProducts)}`} in You Cart
          </div> :
          <div>Your Cart is Empty</div>
        }
        <Button variant="link" onClick={handleOnHide}>
          <i className="fas fa-times"></i>
        </Button>
      </div>
      
      {numberOfProducts > 0 ? 
        <>
          <CartItems showTotal={true} showButtons={true} handleEditCart={handleEditCart} handleCheckout={handleCheckout} />
        </>

        :
        
        <div className="empty-cart-wrap">

          <img src={emptyCartImg} alt="Empty cart" />

          <div className="total-and-buttons">          
            <Link to={packagesUrl} onClick={handleViewPackagesClick}>
              <Button>View Packages & Pricing</Button>
            </Link>
          </div>

        </div>
      }
    
    </div>
  </div>
};

export default CartOverlay