import {
	GET_PRODUCT_PRICE, PURCHASE_PRODUCT,
	CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS,
	CHANGE_PASSWORD_FAILED, RESET_PURCHASE_PRODUCT, COMPLETE_LIVE_COURSE, COMPLETE_LIVE_COURSE_FAILED, UPDATE_SELECTED_LIVE_EVENTS, REMOVE_FROM_SELECTED_LIVE_EVENTS, SET_CART_ID, UPDATE_IS_LIVE_COUPON_BAD, 
	SET_LIVE_CART,
	REMOVE_FROM_LIVE_CART,
	GET_PRODUCTS_PRICE,
	CLEAR_OFFLINE_CART,
	SET_OFFLINE_CART,
	RESET_PURCHASE_PRODUCTS,
	// SET_LIVE_CART_IN_OFFLINE_CART
} from './types';
import AccountAPI from '../api/AccountAPI';
import { isEmpty } from 'lodash';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { getPurchaseMappingTypeName } from '../utils/paymentUtils';

export const getProductPrice = (productInfo) => dispatch => {
	return new Promise((resolve, reject) => {

		if (productInfo.isLive === 1) {
			AccountAPI
				.getProductLivePrice(productInfo)
				.then((productPrice) => {
					dispatch({ type: GET_PRODUCT_PRICE, payload: productPrice });
					resolve(productPrice);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				})
		} else {
			AccountAPI
				.getProductPrice(productInfo)
				.then((productPrice) => {
					dispatch({ type: GET_PRODUCT_PRICE, payload: productPrice });
					resolve(productPrice);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				})
		}
	})
}

export const getProductPrice2 = (productInfo) => dispatch => {
	return new Promise((resolve, reject) => {

		AccountAPI
			.getProductPrice2(productInfo)
			.then((productPrice) => {
				dispatch({ type: GET_PRODUCTS_PRICE, payload: productPrice });
				resolve(productPrice);
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			})
	})
}

export const purchaseProduct = (productInfo, cartId) => dispatch => {

	return new Promise((resolve, reject) => {
		AccountAPI
			.purchaseProduct(productInfo, cartId)
			.then((purchaseProduct) => {
				dispatch({ type: PURCHASE_PRODUCT, payload: purchaseProduct });
				if (purchaseProduct) {
					// dispatch(updateUser(purchaseProduct?.Member));

					if (purchaseProduct.IsAccountCharged === true)
						dispatch(resetPurchaseProduct());
				}
				resolve(purchaseProduct);
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			})
	})
}

export const resetPurchaseProduct = () => dispatch => {
	dispatch({ type: RESET_PURCHASE_PRODUCT });
}

export const resetPurchaseProducts = () => dispatch => {
	dispatch({ type: RESET_PURCHASE_PRODUCTS });
}

export const changePassword = (memberIdentifier, password, confirmPassword) => dispatch => {
	return new Promise((resolve, reject) => {

		dispatch({ type: CHANGE_PASSWORD });

		AccountAPI
			.changePassword(memberIdentifier, password, confirmPassword)
			.then(response => {
				// A Bad Email address will return:
				// { "MemberIdentifier": "00000000-0000-0000-0000-000000000000", "IsSuccessful": false, "ErrorMessage": "Unable to locate member", "StackTrace": "" }
				if (response.IsSuccessful) {
					dispatch({
						type: CHANGE_PASSWORD_SUCCESS,
						payload: response.data
					});
					resolve(response.data);
				} else {
					dispatch({
						type: CHANGE_PASSWORD_FAILED,
						payload: response.ErrorMessage
					});
					reject();
				}
			})
			.catch(error => {
				dispatch({
					type: CHANGE_PASSWORD_FAILED,
					payload: error
				});
				reject(error);
			});
	});
}

export const updatePurchaseInfo = (productInfo) => dispatch => {
	dispatch({ type: PURCHASE_PRODUCT, payload: productInfo });
}

export const completeLiveCourse = (liveEventAttendeeID, completionCode) => dispatch => {
	return new Promise((resolve, reject) => {
		AccountAPI
			.completeLiveCourse(liveEventAttendeeID, completionCode)
			.then(response => {
				if (response === "Success") {
					dispatch({ type: COMPLETE_LIVE_COURSE, payload: liveEventAttendeeID});
					resolve(response);
				} else {
					dispatch({ type: COMPLETE_LIVE_COURSE_FAILED, payload: response });
					reject(response);
				}
			})
			.catch(error => {
				dispatch({ type: COMPLETE_LIVE_COURSE_FAILED, payload: error });
				reject(error);
			});
	});
}

export const rescheduleLiveCourse = (LiveEventAttendeeID, newLiveEventId) => dispatch => {
	return new Promise((resolve, reject) => {
		AccountAPI
			.rescheduleLiveCourse(LiveEventAttendeeID, newLiveEventId)
			.then(response => {
				if (response === "Success") {
					resolve(response);
				} else {
					reject(response);
				}
			})
			.catch(error => {
				reject(error);
			});
	});
}

export const addProductToLiveCart = (product, cartId, memberId) => dispatch => {
	return new Promise((resolve, reject) => {
		AccountAPI
			.addProductToLiveCart(product, cartId, memberId)
			.then(response => {
				dispatch({ type: SET_CART_ID, payload: response.cartId });
				dispatch({ type: UPDATE_SELECTED_LIVE_EVENTS, payload: response.products });
				dispatch({ type: SET_LIVE_CART, payload: response.products });
				dispatch(updateOfflineCart(null, response.cartId, false, null, true));
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export const removeProductFromLiveCart = (cartId, liveEventsId) => dispatch => {
	// if (!cartId || isEmpty(liveEventsId)) return Promise.resolve();

	return new Promise((resolve, reject) => {
		AccountAPI
			.removeProductFromLiveCart(cartId, liveEventsId)
			.then(response => {
				dispatch({ type: REMOVE_FROM_SELECTED_LIVE_EVENTS, payload: liveEventsId });
				dispatch({ type: REMOVE_FROM_LIVE_CART, payload: liveEventsId });
				dispatch(updateOfflineCart(null, cartId, false, null, true));
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export const getLiveCart = (cartId, isFractionalPrice = false) => dispatch => {
	return new Promise((resolve, reject) => {
		AccountAPI
			.getLiveCart(cartId, isFractionalPrice)
			.then(response => {
				dispatch({ type: UPDATE_SELECTED_LIVE_EVENTS, payload: response });
				dispatch({ type: SET_LIVE_CART, payload: response });
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export const clearLiveCart = (cartId, removePermanent = false, updateOffline = true) => dispatch => {
	return new Promise((resolve, reject) => {
		AccountAPI
			.clearLiveCart(cartId)
			.then(response => {
				dispatch({ type: UPDATE_SELECTED_LIVE_EVENTS, payload: [] });
				dispatch({ type: SET_LIVE_CART, payload: [] });
				if (removePermanent) dispatch({ type: SET_CART_ID, payload: null });
				if (updateOffline) dispatch(updateOfflineCart(null, cartId, false, null, true));
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export const getLiveCartPrice = (cartId, couponCode, alreadyAppliedDiscount, isFractionalPrice) => dispatch => {
	return new Promise((resolve, reject) => {
		AccountAPI
			.getLiveCartPrice(cartId, couponCode, alreadyAppliedDiscount, isFractionalPrice)
			.then(response => {
				let isLiveCouponBad = false;

				if (!isEmpty(response)) {
					isLiveCouponBad = !response[0]?.IsSuccessful;
				}

				dispatch({ type: UPDATE_SELECTED_LIVE_EVENTS, payload: response });
				dispatch({ type: SET_LIVE_CART, payload: response });
				dispatch({ type: UPDATE_IS_LIVE_COUPON_BAD, payload: isLiveCouponBad });
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export const sendStartedCheckout = (accountModel) => dispatch => {
	return new Promise((resolve, reject) => {
		AccountAPI
			.sendStartedCheckout(accountModel)
			.then(response => {
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export const updateOfflineCart = (memberID, liveCartID, purchased, product, doNotTouchProduct = false, selectedBundle2 = null) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		
		// Get offline guid from cookie and set it if it doesn't exist
		let offline_guid = Cookies.get('lexvid_offline_guid');
		if (!offline_guid) {
			offline_guid = uuidv4();
			Cookies.set('lexvid_offline_guid', offline_guid, { expires: 30 });
		};
		
		const state = getState();

		if (!memberID) {
			memberID = state.auth?.user?.ID;
		}

		// 
		if (doNotTouchProduct) {
			const productFromRedux = state.account?.offlineCart?.Product;
			product = productFromRedux;
		}

		// Get current live cart 
		const selectLiveCartId = state => state.productBundles.cartId;
		const selectLiveCourses = state => state.productBundles.selectedLiveEvents;
		const selectIncludeSelectedLiveEvents = state => state.productBundles.includeSelectedLiveEvents;

		const liveCartIDFromRedux = selectLiveCartId(state);
		const selectedLiveEvents = selectLiveCourses(state);
		const includeSelectedLiveEventsFromStore = selectIncludeSelectedLiveEvents(state);
		const hasLiveCourses = includeSelectedLiveEventsFromStore && selectedLiveEvents?.length > 0;

		// Determine PurchaseMappingTypeName
		const purchaseMappingTypeName = getPurchaseMappingTypeName(product, hasLiveCourses, selectedBundle2);

		const data = {
			OfflineMemberID: offline_guid,
			MemberID: memberID ?? 0,
			LiveCartID: liveCartID ?? liveCartIDFromRedux,
			Purchased: purchased ?? false,
			Product: product,
			PurchaseMappingTypeName: purchaseMappingTypeName
		};

		AccountAPI
			.offlineCart(data)
			.then(response => {
				dispatch({ type: SET_OFFLINE_CART, payload: response });
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export const updateOfflineCartPurchased = (memberID) => dispatch => {
	return new Promise((resolve, reject) => {
		let offline_guid = Cookies.get('lexvid_offline_guid');

		const data = {
			OfflineMemberID: offline_guid,
			MemberID: memberID,
			Purchased: true
		};

		AccountAPI
			.offlineCart(data)
			.then(response => {
				dispatch({ type: SET_OFFLINE_CART, payload: response });
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	});
}

// export const addLiveCartToOfflineCart = (liveCartID) => dispatch => {
// 	return new Promise((resolve, reject) => {
// 		const offline_guid = Cookies.get('lexvid_offline_guid');

// 		AccountAPI
// 			.addLiveCartToOfflineCart(offline_guid, liveCartID)
// 			.then(response => {
// 				dispatch({ type: SET_LIVE_CART_IN_OFFLINE_CART, payload: response });
// 				resolve(response);
// 			})
// 			.catch(error => {
// 				reject(error);
// 			});
// 	});
// }

export const getOfflineCart = () => dispatch => {
	return new Promise((resolve, reject) => {
		dispatch({ type: CLEAR_OFFLINE_CART });

		const offline_guid = Cookies.get('lexvid_offline_guid');

		AccountAPI
			.getOfflineCart(offline_guid)
			.then(response => {
				dispatch({ type: SET_OFFLINE_CART, payload: response });
				resolve(response);
			})
			.catch(error => {
				reject(error);
			});
	});
}