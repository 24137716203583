import {
	GET_PRODUCT_PRICE,
	PURCHASE_PRODUCT, CHANGE_PASSWORD,
	CHANGE_PASSWORD_SUCCESS,
	CHANGE_PASSWORD_FAILED,
	RESET_PURCHASE_PRODUCT,
	RESET_ERROR_MESSAGE_COUPON,
	COMPLETE_LIVE_COURSE,
	COMPLETE_LIVE_COURSE_FAILED,
	GET_PRODUCTS_PRICE,
	RESET_PURCHASE_PRODUCTS,
	RESET_ERROR_MESSAGE,
	CLEAR_OFFLINE_CART,
	SET_OFFLINE_CART,
	SET_LIVE_CART_IN_OFFLINE_CART
} from '../actions/types';

const initialState = {
	purchaseProduct: null,
	purchaseProducts: null,
	changePassword: {},
	liveCourseCodeError: null,
	offlineCart: null
}

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_PRODUCT_PRICE:
			{
				let purchaseProduct;

				if (action.payload?.IsBadCoupon)
					purchaseProduct = {
						...action.payload,
						RetailPrice: state.purchaseProduct.RetailPrice,
						ErrorMessageCoupon: action.payload.ErrorMessage
					};
				else purchaseProduct = {
					...action.payload, ErrorMessageCoupon: state.purchaseProduct?.ErrorMessageCoupon
				};

				return {
					...state,
					purchaseProduct: purchaseProduct
				}
			}
		case GET_PRODUCTS_PRICE:
			{
				let purchaseProducts;

				if (action.payload?.IsBadCoupon) {
					purchaseProducts = {
						...action.payload,
						ErrorMessageCoupon: action.payload.ErrorMessage
					};

					if (state.purchaseProduct?.ProductItems && state.purchaseProduct?.ProductItems[0]) {
						purchaseProducts.ProductItems[0].Price = state.purchaseProduct.ProductItems[0].Price;
						purchaseProducts.ProductItems[0].RetailPrice = state.purchaseProduct.ProductItems[0].RetailPrice ?? purchaseProducts.ProductItems[0].RetailPrice;
					}
					if (state.purchaseProduct?.ProductItems && state.purchaseProduct?.ProductItems[1]) {
						purchaseProducts.ProductItems[1].Price = state.purchaseProduct.ProductItems[1].Price;
						purchaseProducts.ProductItems[1].RetailPrice = state.purchaseProduct.ProductItems[1].RetailPrice;
					}

					if (state.purchaseProducts?.ProductItems && state.purchaseProducts?.ProductItems[0]) {
						purchaseProducts.ProductItems[0].Price = state.purchaseProducts.ProductItems[0].Price;
						purchaseProducts.ProductItems[0].RetailPrice = state.purchaseProducts.ProductItems[0].RetailPrice ?? purchaseProducts.ProductItems[0].RetailPrice;
						purchaseProducts.ProductItems[0].PurchasedCredits = state.purchaseProducts.ProductItems[0].PurchasedCredits;
						purchaseProducts.ProductItems[0].PurchasedLiveCredits = state.purchaseProducts.ProductItems[0].Purchased
					}
					if (state.purchaseProducts?.ProductItems && state.purchaseProducts?.ProductItems[1]) {
						purchaseProducts.ProductItems[1].Price = state.purchaseProducts.ProductItems[1].Price;
						purchaseProducts.ProductItems[1].RetailPrice = state.purchaseProducts.ProductItems[1].RetailPrice;
						purchaseProducts.ProductItems[1].PurchasedCredits = state.purchaseProducts.ProductItems[1].PurchasedCredits;
						purchaseProducts.ProductItems[1].PurchasedLiveCredits = state.purchaseProducts.ProductItems[1].PurchasedLiveCredits;
					}

				} else {
					purchaseProducts = {
						...action.payload, ErrorMessageCoupon: state.purchaseProduct?.ErrorMessageCoupon
					};
				}

				return {
					...state,
					purchaseProducts: purchaseProducts
				}
			}
		case RESET_ERROR_MESSAGE: {
			return {
				...state,
				purchaseProduct: { ...state.purchaseProduct, ErrorMessage: '' }
			}
		}
		case RESET_ERROR_MESSAGE_COUPON: {
			return {
				...state,
				purchaseProduct: { ...state.purchaseProduct, ErrorMessageCoupon: '' }
			}
		}
		case PURCHASE_PRODUCT:
			{
				return {
					...state,
					purchaseProduct: action.payload
				}
			}
		case CHANGE_PASSWORD:
			{
				return {
					...state,
				}
			}
		case CHANGE_PASSWORD_SUCCESS:
			{
				return {
					...state,
					changePassword: action.payload
				}
			}
		case CHANGE_PASSWORD_FAILED:
			{
				return {
					...state,
					changePassword: action.payload
				}
			}
		case RESET_PURCHASE_PRODUCT:
			{
				return {
					...state,
					purchaseProduct: null
				}
			}
		case RESET_PURCHASE_PRODUCTS:
			{
				return {
					...state,
					purchaseProducts: null
				}
			}
		case COMPLETE_LIVE_COURSE:
			{
				return {
					...state,
					liveCourseCodeError: null
				}
			}
		case COMPLETE_LIVE_COURSE_FAILED:
			{
				return {
					...state,
					liveCourseCodeError: action.payload
				}
			}
		case CLEAR_OFFLINE_CART:
			{
				return {
					...state,
					offlineCart: null
				}
			}	
		case SET_OFFLINE_CART:
			{
				// set Product to null if Product.ProductType = "Unknown" or Product.ProductTypeID = 0
				if (action.payload?.Product?.ProductType === "Unknown" || action.payload?.Product?.ProductTypeID === 0) {
					action.payload.Product = null;
				}

				return {
					...state,
					offlineCart: action.payload
				}
			}
		case SET_LIVE_CART_IN_OFFLINE_CART:
			{
				return {
					...state,
					offlineCart: {
						...state.offlineCart,
						Info: {
							...state.offlineCart?.Info,
							LiveCartID: action.payload?.Info?.LiveCartID
						}
						
					}
					// offlineCart: action.payload
				}
			}
		default:
		return state;
	}
}