import axios from '../utils/axiosInterceptors';
import * as Sentry from '@sentry/react';

const baseURL = process.env.REACT_APP_LEXVID_API;

const getTestimonials = () => {
	let URL = `${baseURL}/api/Testimonial`;

	const testimonials = axios({ method: 'GET', url: URL }).then((response) => {
		if (response.status === 200)
			return response.data;
	}
	).catch((error) => {
		console.log(error);
	});

	return testimonials;
}

const getIncomes = () => {
	let URL = `${baseURL}/api/FinancialAid/Income`;

	const incomes = axios({ method: 'GET', url: URL }).then((response) => {
		if (response.status === 200)
			return response.data;
	}
	).catch((error) => {
		console.log(error);
	});

	return incomes;
}

const getTermsContent = () => {
	let URL = `${baseURL}/api/Content/Terms`;

	const content = axios({ method: 'GET', url: URL }).then((response) => {
		if (response.status === 200)
			return response.data;
	}
	).catch((error) => {
		console.log(error);
	});

	return content;
}

const getBars = () => {

	const url = `${baseURL}/api/bar`;

	const bars = axios.get(url)
		.then(response => {
			if (response.status === 200)
				return response;
		})
		.catch(error => {
			console.log(error);
		});

	return bars;
}

const validateEmail = (email) => {
	let URL = `${baseURL}/api/Account/Validate?email=${encodeURIComponent(email)}`;

	const isEmailValid = axios({
		method: 'GET',
		url: URL
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		console.log(error);
	});

	return isEmailValid;
}

const submitLawFirmInquiry = (data) => {

	let URL = `${baseURL}/api/LawFirmInquiry`;

	const response = axios({
		method: 'POST',
		url: URL,
		data: data
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		console.log(error);
	});

	return response;
}

const submitSpeakerInquiry = (data) => {

	let URL = `${baseURL}/api/speaker/inquiry`;

	const response = axios({
		method: 'POST',
		url: URL,
		data: data
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		console.log(error);
	});

	return response;
}

const logAppError = (data) => {
	let url = `${baseURL}/api/Error`;

	const response = axios({
		method: 'POST',
		url: url,
		data: data
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		console.log(error);
	});

	return response;
}

// Accepts following codes 500, 501, 401
const triggerAPIError = (errorCode) => {
	let url = `${baseURL}/api/member/errorgenerator?code=`;


	if (errorCode) url += errorCode;

	const response = axios({
		method: 'GET',
		url: url
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		Sentry.captureException(error);
		console.log(error);
	});

	return response;
}

const triggerAPI401Error = () => {
	const url = `${baseURL}/api/Member/shallow`;

	const member = axios.get(
		url, 
		{ 
			transformRequest: (data, headers) => {
    		delete headers.common['Authorization'];
    		return data;
  		}
		})
		.then(response => {
			if (response.status === 200)
				return response.data;
		})
		.catch(error => {
			console.log(error);
		});

	return member;
}

const getBlogCategories = () => {
	const url = `${baseURL}/api/blog/category/list`;

	const categories = axios.get(url)
		.then(response => {
			if (response.status === 200)
				return response.data;
		})
		.catch(error => {
			console.log(error);
		});

	return categories;
}

const getBlogPosts = () => {
	const url = `${baseURL}/api/blog/post/list`;

	const posts = axios.get(url)
		.then(response => {
			if (response.status === 200)
				return response.data;
		})
		.catch(error => {
			console.log(error);
		});

	return posts;
}

const getPackagesContentWithKlavID = (klaviyoUniqueID, isMultiState) => {
	let url = `${baseURL}/api/content/packages?klavID=${klaviyoUniqueID}`;

	if (isMultiState) {
		url = `${baseURL}/api/Content/multistate-packages?klavID=${klaviyoUniqueID}`;
	}

	const response = axios.get(url)
		.then(response => {
			if (response.status === 200)
				return response.data;
		})
		.catch(error => {
			console.log(error);
		});

	return response;
}

const getWebAppVariables = () => {
	const url = `${baseURL}/api/WebAppVariables`;

	const variables = axios.get(url)
		.then(response => {
			if (response.status === 200)
				return response.data;
		})
		.catch(error => {
			console.log(error);
		});

	return variables;
}

export default {
	getTestimonials,
	getIncomes,
	getTermsContent,
	getBars,
	validateEmail,
	submitLawFirmInquiry,
	submitSpeakerInquiry,
	logAppError,
	triggerAPIError,
	triggerAPI401Error,
	getBlogCategories,
	getBlogPosts,
	getPackagesContentWithKlavID,
	getWebAppVariables
}