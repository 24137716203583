import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLiveCart } from "../actions/accountActions";

export default function useLiveEventsCheckoutData(doNotRefreshCart = false) {

	const dispatch = useDispatch();

	const includeSelectedLiveEventsFromStore = useSelector(state => state.productBundles.includeSelectedLiveEvents);
	const selectedLiveEvents = useSelector(state => state.productBundles.selectedLiveEvents);
	const includeSelectedLiveEvents = includeSelectedLiveEventsFromStore && selectedLiveEvents?.length > 0;
	const cartId = useSelector(state => state.productBundles.cartId);
	const isFractionalPrice = useSelector(state => state.productBundles.isFractionalPrice);

	// Restore current live cart
	useEffect(() => {
		if (doNotRefreshCart) return;

		if (cartId) dispatch(getLiveCart(cartId, isFractionalPrice));

	}, [cartId, dispatch, isFractionalPrice, doNotRefreshCart]);


    return {
        cartId, selectedLiveEvents, includeSelectedLiveEvents
	}
}