import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

const useNumberProductsInCart = () => {

  const selectedBundle = useSelector(state => state.productBundles.selectedBundle);
  // console.log(selectedBundle);
  
  const selectedBundle2 = useSelector(state => state.productBundles.selectedBundle2);

  const liveCoursesCart = useSelector(state => state.productBundles.liveCart);
	const includeSelectedLiveEvents = useSelector(state => state.productBundles.includeSelectedLiveEvents);

  const numberOfProducts = (!isEmpty(selectedBundle) ? 1 : 0) + (!isEmpty(selectedBundle2) ? 1 : 0) + (includeSelectedLiveEvents && liveCoursesCart && Array.isArray(liveCoursesCart) ? liveCoursesCart.length : 0);

  return numberOfProducts;
};

export default useNumberProductsInCart;