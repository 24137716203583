import {
	GET_TESTIMONIALS, GET_INCOMES, GET_CAMPAIGN, GET_REFERRER, GET_BARS,
	IS_EMAIL_VALID_SUCCESS, GET_REFERRAL_PAGE, IS_EMAIL_VALID,
	SUBMIT_LAW_FIRM_INQUIRY,
	SUBMIT_LAW_FIRM_INQUIRY_SUCCESS, SET_ADD_COURSE_AFTER_SIGNIN,
	SUBMIT_SPEAKER_INQUIRY, SUBMIT_SPEAKER_INQUIRY_SUCCESS, SET_REDIRECT_AFTER_SIGNIN,
	CLEAR_REDIRECT_AFTER_SIGNIN,
	SET_WINDOW_DIMENSIONS,
	SET_APP_ERROR,
	GET_BLOG_CATEGORIES,
	SET_BLOG_SELECTED_CATEGORY,
	GET_BLOG_POSTS,
	SET_SHOW_FINISH_SETTING_UP_ACCOUNT,
	SET_FREE_SIGNUP_SUCCESS_URL,
	SET_SHOW_CART_OVERLAY,
	SET_WEB_APP_VARIABLES
} from '../actions/types';

const initialState = {
	testimonials: [],
	incomes: [],
	campaign: '',
	bars: [],
	isEmailValid: null,
	referralContent: {},
	lawFirmInquiry: {},
	addCourseID: null,
	speakerInquiry: {},
	redirectPageUrl: null,
	windowDimensions: null,
	error: null,
	showFinishSettingUpAccount: false,
	freeSignupSuccessUrl: null,
	showCartOverlay: false,
	webAppVariables: {},
	blogCategories: [],
	blogSelectedCategory: null,
	blogPosts: []
}

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_SHOW_CART_OVERLAY:
			{
				return {
					...state,
					showCartOverlay: action.payload
				}
			}
		case GET_TESTIMONIALS:
			{
				return {
					...state,
					testimonials: action.payload
				}
			}
		case GET_INCOMES:
			{
				return {
					...state,
					incomes: action.payload
				}
			}
		case GET_CAMPAIGN:
			{
				return {
					...state,
					campaign: action.payload
				}
			}
		case GET_REFERRER:
			{
				return {
					...state,
					referrer: action.payload
				}
			}
		case GET_BARS:
			{
				return {
					...state,
					bars: action.payload
				}
			}
		case IS_EMAIL_VALID:
			{
				return {
					...state,
					isEmailValid: null,
				}
			}
		case IS_EMAIL_VALID_SUCCESS:
			{
				return {
					...state,
					isEmailValid: action.payload
				}
			}
		case GET_REFERRAL_PAGE:
			{
				return {
					...state,
					referralContent: action.payload
				}
			}
		case SUBMIT_LAW_FIRM_INQUIRY:
			{
				return {
					...state,
					lawFirmInquiry: {}
				}
			}
		case SUBMIT_LAW_FIRM_INQUIRY_SUCCESS:
			{
				return {
					...state,
					lawFirmInquiry: action.payload
				}
			}
		case SUBMIT_SPEAKER_INQUIRY:
			{
				return {
					...state,
					speakerInquiry: {}
				}
			}
		case SUBMIT_SPEAKER_INQUIRY_SUCCESS:
			{
				return {
					...state,
					speakerInquiry: action.payload
				}
			}
		case SET_ADD_COURSE_AFTER_SIGNIN:
			{
				return {
					...state,
					addCourseID: action.payload
				}
			}
		case SET_REDIRECT_AFTER_SIGNIN:
			{
				return {
					...state,
					redirectPageUrl: action.payload
				}
			}
		case CLEAR_REDIRECT_AFTER_SIGNIN:
			{
				return {
					...state,
					redirectPageUrl: null
				}
			}
		case SET_WINDOW_DIMENSIONS:
			{
				return {
					...state,
					windowDimensions: action.payload
				}
			}
		case SET_APP_ERROR:
			{
				return {
					...state,
					error: action.payload
				}
			}
		case GET_BLOG_CATEGORIES:
			{
				return {
					...state,
					blogCategories: action.payload
				}
			}
		case SET_BLOG_SELECTED_CATEGORY:
			{
				return {
					...state,
					blogSelectedCategory: action.payload
				}
			}
		case GET_BLOG_POSTS:
			{
				return {
					...state,
					blogPosts: action.payload
				}
			}
		case SET_SHOW_FINISH_SETTING_UP_ACCOUNT:
			{
				return {
					...state,
					showFinishSettingUpAccount: action.payload
				}
			}
		case SET_FREE_SIGNUP_SUCCESS_URL:
			{
				return {
					...state,
					freeSignupSuccessUrl: action.payload
				}
			}
		case SET_WEB_APP_VARIABLES:
			{
				return {
					...state,
					webAppVariables: action.payload
				}
			}
		default:
			return state;
	}
}