import React, { useCallback } from 'react';
import useNumberProductsInCart from "../../../hooks/useNumberProductsInCart";
import './CartIcon.scss';
import { useDispatch, useSelector } from "react-redux";
import { SET_SHOW_CART_OVERLAY } from "../../../actions/types";


const CartIcon = () => {

  const dispatch = useDispatch();
  const showCartOverlay = useSelector(state => state.miscellaneous.showCartOverlay);

  const setShowCartOverlay = useCallback((show) => {
    dispatch({ type: SET_SHOW_CART_OVERLAY, payload: show });
  }, [dispatch]);

  const numberOfProducts = useNumberProductsInCart();

  const handleClick = () => {
    setShowCartOverlay(!showCartOverlay);
  }

  return <div class="cart-icon-wrap" onClick={handleClick}>

    <div className='shopping-cart-icon'>
      <i className="fas fa-shopping-cart"></i>
      
      {numberOfProducts > 0 ? <>
        <i className="fas fa-circle transparent-circle"></i>
        <i className="fas fa-circle orange-circle"></i>
        <span className="number-of-products">{numberOfProducts}</span>
      </> : null}

    </div>
  </div>
};

export default CartIcon;