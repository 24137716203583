import axios from '../utils/axiosInterceptors';

const baseURL = process.env.REACT_APP_LEXVID_API;

const getMemberPlaylist = () => {
	let URL = `${baseURL}/api/Member/playlist`;

	const memberPlaylist = axios({
		method: 'GET',
		url: URL,
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}
	).catch((error) => {
		console.log(error);
	});

	return memberPlaylist;
}

const deleteMemberPlaylistCourse = (courseID) => {
	let URL = `${baseURL}/api/Member/playlist/${courseID}`;

	const memberPlaylist = axios({
		method: 'DELETE',
		url: URL,
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}
	).catch((error) => {
		console.log(error);
	});

	return memberPlaylist;
}

const removeMemberPlaylistCourse = (courseID) => {
	let URL = `${baseURL}/api/Member/playlist/${courseID}`;

	const memberPlaylist = axios({
		method: 'DELETE',
		url: URL,
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}
	).catch((error) => {
		console.log(error);
	});

	return memberPlaylist;
}


const addCourseToPlaylist = (courseID) => {
	let URL = `${baseURL}/api/Member/playlist/${courseID}`;

	const memberPlaylist = axios({
		method: 'POST',
		url: URL,
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}
	).catch((error) => {
		console.log(error);
	});

	return memberPlaylist;
}

const getMemberDeadlines = () => {
	let URL = `${baseURL}/api/Member/deadlines`;

	const memberDeadlines = axios({
		method: 'GET',
		url: URL
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}
	).catch((error) => {
		console.log(error);
	});

	return memberDeadlines;
}

const getCompletedCourses = (stateID, periodID) => {
	let URL = `${baseURL}/api/Member/completed?stateid=${stateID}&periodid=${periodID}`;

	const completedCourses = axios({
		method: 'GET',
		url: URL,
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}
	).catch((error) => {
		console.log(error);
	});

	return completedCourses;
}

const getMemberCompliance = () => {
	let URL = `${baseURL}/api/Member/compliance`;

	const compliance = axios({
		method: 'GET',
		url: URL,
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}
	).catch((error) => {
		console.log(error);
	});

	return compliance;
}

const getMemberPeriods = (stateID) => {
	let URL = `${baseURL}/api/Member/MovePeriods/${stateID}`;

	const memberPeriods = axios({
		method: 'GET',
		url: URL,
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}
	).catch((error) => {
		console.log(error);
	});

	return memberPeriods;
}

const moveMemberCredits = (data) => {
	let URL = `${baseURL}/api/Member/MoveCredits`;

	const credits = axios({
		method: 'POST',
		url: URL,
		data: data
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}
	).catch((error) => {
		console.log(error);
	});

	return credits;
}

const getMemberTransactions = () => {
	let URL = `${baseURL}/api/Member/transactions`;

	const transactions = axios({
		method: 'GET',
		url: URL
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		console.log(error);
	});

	return transactions;
}

const addMemberPracticeArea = (practiceAreaID) => {
	let URL = `${baseURL}/api/Member/practicearea/${practiceAreaID}`;

	const member = axios({
		method: 'POST',
		url: URL
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		console.log(error);
	});

	return member;
}

const deleteMemberPracticeArea = (practiceAreaID) => {
	let URL = `${baseURL}/api/Member/practicearea/${practiceAreaID}`;

	const member = axios({
		method: 'DELETE',
		url: URL
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		console.log(error);
	});

	return member;
}

const editMemberShallow = (memberData) => {
	let URL = `${baseURL}/api/Member/shallow`;

	const member = axios({
		method: 'POST',
		url: URL,
		data: memberData
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		console.log(error);
	});

	return member;
}

const editMemberContact = (memberData) => {
	let URL = `${baseURL}/api/Member/contact`;

	const member = axios({
		method: 'POST',
		url: URL,
		data: memberData
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		console.log(error);
	});

	return member;
}

const updateMemberBar = (barData) => {
	let URL = `${baseURL}/api/Member/bar`;

	const memberBar = axios({
		method: 'POST',
		url: URL,
		data: barData
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		console.log(error);
	});

	return memberBar;
}

const deleteMemberBar = (barID) => {
	let URL = `${baseURL}/api/Member/bar/${barID}`;

	const memberBar = axios({
		method: 'DELETE',
		url: URL
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		console.log(error);
	});

	return memberBar;
}

const getDeadlineConfig = (stateID) => {
	let URL = `${baseURL}/api/Member/${stateID}/DeadlineConfig`;

	const deadlineConfig = axios({
		method: 'GET',
		url: URL
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		console.log(error);
	});

	return deadlineConfig;
}

const getAllDeadlineConfigs = () => {
	let URL = `${baseURL}/api/Member/AllDeadlineConfig`;

	const deadlineConfigs = axios({
		method: 'GET',
		url: URL
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		console.log(error);
	});	

	return deadlineConfigs;
}

const getMemberReferral = () => {
	let URL = `${baseURL}/api/Member/referral`;

	const referralData = axios({
		method: 'GET',
		url: URL
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		console.log(error);
	});

	return referralData;
}

const getMemberNotifications = () => {
	const URL = `${baseURL}/api/Notifications`;

	const notifications = axios({
		method: 'GET',
		url: URL,
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		console.log(error);
	});

	return notifications;
}

const deleteMemberNotification = (id, stateID) => {
	let URL = `${baseURL}/api/Notifications`;

	const notifications = axios({
		method: 'DELETE',
		url: URL,
		data: { ID: id, StateID: stateID }
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}
	).catch((error) => {
		console.log(error);
	});

	return notifications;
}

const trackMemberPageRequest = (pageURL) => {
	let URL = `${baseURL}/api/Member/PageRequest`;

	const pageRequest = axios({
		method: 'POST',
		url: URL,
		data: {
			PageURL: pageURL
		}
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	});

	return pageRequest;
}

const createOutsideCredits = (credits) => {
	let URL = `${baseURL}/api/Member/outside`;

	const outsideCredits = axios({
		method: 'POST',
		url: URL,
		data: credits
	})
		.then((response) => {
			if (response.status === 200)
				return response.data;
		})
		.catch((error) => {
			console.log(error);
		});

	return outsideCredits;
}

const deleteOutsideCredit = (id) => {
	let URL = `${baseURL}/api/Member/outside/${id}`;

	const promise = axios({
		method: 'DELETE',
		url: URL
	});

	const dataPromise = promise
		.then((response) => response.data)
		.catch((error) => console.log(error));

	return dataPromise;
}

const checkLiveCourse = (memberId) => {
	const URL = `${baseURL}/api/Member/LiveCourseCheck/${memberId}`;
	// const URL = `${baseURL}/api/Member/LiveCourseCheckInProgress/${memberId}`;

	const liveCourse = axios({
		method: 'GET',
		url: URL,
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		console.log(error);
	});

	return liveCourse;
}

const addMemberNameAndBars = (memberData) => {
	let URL = `${baseURL}/api/Member/NameAndBars`;

	const member = axios({
		method: 'POST',
		url: URL,
		data: memberData
	}).then((response) => {
		if (response.status === 200)
			return response.data;
	}).catch((error) => {
		console.log(error);
	});

	return member;
}


export default {
	getMemberPlaylist,
	deleteMemberPlaylistCourse,
	addCourseToPlaylist,
	getMemberDeadlines,
	getCompletedCourses,
	getMemberCompliance,
	getMemberPeriods,
	moveMemberCredits,
	getMemberTransactions,
	addMemberPracticeArea,
	deleteMemberPracticeArea,
	editMemberShallow,
	editMemberContact,
	updateMemberBar,
	deleteMemberBar,
	getDeadlineConfig,
	getAllDeadlineConfigs,
	getMemberReferral,
	getMemberNotifications,
	deleteMemberNotification,
	removeMemberPlaylistCourse,
	trackMemberPageRequest,
	createOutsideCredits,
	deleteOutsideCredit,
	checkLiveCourse,
	addMemberNameAndBars
}